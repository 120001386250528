/* @import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap'); */

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/titillium-web-v7-latin-regular.eot');
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
        url('../fonts/titillium-web-v7-latin-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/titillium-web-v7-latin-regular.woff2') format('woff2'),
        url('../fonts/titillium-web-v7-latin-regular.woff') format('woff'),
        url('../fonts/titillium-web-v7-latin-regular.ttf') format('truetype'),
        url('../fonts/titillium-web-v7-latin-regular.svg#TitilliumWeb') format('svg');
}

html,
body,
#root {
    display: flex;
    flex-flow: column;
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    background-color: whitesmoke;
}

h1,
h2 {
    font-size: 1.5rem;
    margin: 0.75em 0;
}

@media (min-width: 769px) {
    h1,
    h2 {
        font-size: 2rem;
    }
}

h3 {
    font-size: 1.25rem;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
}

p {
    font-size: 1.1rem;
}

a {
    color: #212529;
    overflow-wrap: break-word;
}

a.no-underline {
    text-decoration: none;
}

a:hover {
    /* ${Colors.orange} */
    color: #f2a60c;
}